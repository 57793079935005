<template>
  <div>
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>4. Исполнение</strong>
    </div>
    <div class="row" v-if="!isExecutionDocumentsAvailable">
      <div class="col-xl-12">
        <div class="alert alert-danger"><strong>Дата получения исп.документа</strong> в блоке «Решение» не заполнена</div>
      </div>
    </div>
    <div class="row" v-if="!isExecutionDocumentsExist && isExecutionDocumentsAvailable">
      <div class="col-xl-12">
        <div class="alert alert-success"><strong>Для того, чтобы отобразились ответчики, нажмите кнопку «Сохранить изменения»</strong></div>
      </div>
    </div>
    <div class="row" v-if="isExecutionDocumentsAvailable">
      <div class="col-xl-12">
        <div class="row w-100 m-0 p-0">
          <div class="col respondents-container">
            <div class="" style="overflow-x: auto">
              <div class="text-uppercase fw-bold alert-primary my-2">Ответчики - Офис</div>
              <Container
                class="w-100"
                orientation="horizontal"
                group-name="col"
                drag-class="card-ghost"
                drop-class="card-ghost-drop"
                @drop="(e) => onCardDrop(1, e)"
                :get-child-payload="getCardPayload(states.Office.id)"
                :drop-placeholder="dropPlaceholderOptions"
              >
                <Draggable v-for="card in store.executionDocumentsOffice" :key="card.id">
                  <div class="card draggable-item-horizontal respondent">
                    <MovedItem :item="card" @change="(item) => itemChange(item, card.id)" @delete="(item) => deleteMove(item)" />
                  </div>
                </Draggable>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="row w-100 p-0 m-0" v-if="isExecutionDocumentsAvailable">
          <div class="col state" v-for="column in columns" :key="column.id">
            <div class="text-uppercase fw-bold my-2 alert-primary">{{ column.caption }}</div>
            <Container
              class="h-100"
              group-name="col"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              @drop="(e) => onCardDrop(column.id, e)"
              :get-child-payload="getCardPayload(column.id)"
              :drop-placeholder="dropPlaceholderOptions"
            >
              <Draggable v-for="card in getColumnChilds(column.id)" :key="card.id">
                <div class="draggable-item">
                  <MovedItem
                    :item="card"
                    :additionalInfo="{
                      courtDecisionSum: writ.courtDecisionSum,
                      courtDecisionStateFee: writ.courtDecisionStateFee,
                      courtDecisionPenalties: writ.courtDecisionPenalties,
                    }"
                    @change="(item) => itemChange(item, card.id)"
                    @generate="(item) => generate(item, card.id)"
                    @download="(item) => download(item)"
                    @delete="(item) => deleteMove(item)"
                  />
                </div>
              </Draggable>
            </Container>
          </div>
        </div>
      </div>
    </div>

    <DxPopupModal
      ref="dxPopup"
      :visible="currentMoveItemTo != null"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="false"
      :show-title="false"
      :width="700"
      height="auto"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="h-100">
        <Office
          v-if="currentMoveItemTo == states.Office.id"
          :item="currentMoveItem"
          @cancel="cancelMove"
          @save="(item) => saveMove(item, states.Office.id)"
        />
        <Bank
          v-else-if="currentMoveItemTo == states.Bank.id"
          :item="currentMoveItem"
          :additionalInfo="{
            courtDecisionSum: writ.courtDecisionSum,
            courtDecisionStateFee: writ.courtDecisionStateFee,
            bailiff: bailiff,
          }"
          @cancel="cancelMove"
          @save="(item) => saveMove(item, states.Bank.id)"
          @generate="(item) => generate(item, states.Bank.id)"
          @download="(item) => download(item)"
        />
        <Ssp
          v-else-if="currentMoveItemTo == states.Ssp.id"
          :item="currentMoveItem"
          :additionalInfo="{
            courtDecisionSum: writ.courtDecisionSum,
            courtDecisionStateFee: writ.courtDecisionStateFee,
            courtDecisionPenalties: writ.courtDecisionPenalties,
            bailiff: bailiff,
          }"
          @cancel="cancelMove"
          @save="(item) => saveMove(item, states.Ssp.id)"
          @generate="(item) => generate(item, states.Ssp.id)"
          @download="(item) => download(item)"
        />
        <Pfr
          v-else-if="currentMoveItemTo == states.Pfr.id"
          :item="currentMoveItem"
          :additionalInfo="{
            courtDecisionSum: writ.courtDecisionSum,
            courtDecisionStateFee: writ.courtDecisionStateFee,
            bailiff: bailiff,
          }"
          @cancel="cancelMove"
          @save="(item) => saveMove(item, states.Pfr.id)"
          @generate="(item) => generate(item, states.Pfr.id)"
          @download="(item) => download(item)"
        />
      </div>
    </DxPopupModal>
  </div>
</template>

<script>
import { provide } from "vue";
import { confirm } from "devextreme/ui/dialog";
import { Container, Draggable } from "vue-dndrop";
import { formatDate } from "devextreme/localization";
import Office from "./Execution/EditItemOffice";
import Bank from "./Execution/EditItemBank";
import Ssp from "./Execution/EditItemSsp";
import Pfr from "./Execution/EditItemPfr";
import MovedItem from "./Execution/MovedItem";

import { storeToRefs } from "pinia";
import { useWritCardExecutionStore } from "../../state/writ.card.execution";
import { useWritCardStore } from "../../state/writ.card";
import { mapGetters } from "vuex";

const states = {
  Office: { text: "Office", id: 1 },
  Bank: { text: "Bank", id: 2 },
  Pfr: { text: "Pfr", id: 3 },
  Ssp: { text: "Ssp", id: 4 },
};

export default {
  components: {
    Container,
    Draggable,
    Office,
    Bank,
    Ssp,
    Pfr,
    MovedItem,
  },
  emits: ["generateExecutionDocument"],
  setup() {
    provide("states", states);

    const writStore = useWritCardStore();
    const { writ } = storeToRefs(writStore);

    const store = useWritCardExecutionStore();
    const { executionDocuments } = storeToRefs(store);

    return { store, executionDocuments, writ };
  },
  computed: {
    ...mapGetters({
      debtorData: "debtorCardInfo/debtorData",
    }),

    bailiff: {
      get() {
        return this.debtorData.house && this.debtorData.house.bailiff ? this.debtorData.house.bailiff.name : "";
      },
    },

    isExecutionDocumentsAvailable() {
      return (
        this.writ.courtDecisionDate != null &&
        ((this.writ.courtDecisionSum && this.writ.courtDecisionSum != 0) ||
          (this.writ.courtDecisionStateFee && this.writ.courtDecisionStateFee != 0))
      );
    },
    isExecutionDocumentsExist() {
      return this.store.executionDocuments.length != 0;
    },
  },
  data() {
    return {
      formatDate,
      states,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      columns: [
        { id: states.Ssp.id, caption: "ССП" },
        { id: states.Pfr.id, caption: "ПФР" },
        { id: states.Bank.id, caption: "Банк" },
      ],
      currentMoveItem: null,
      currentMoveItemTo: null,
    };
  },

  methods: {
    getColumnChilds(columnId) {
      switch (columnId) {
        case this.states.Office.id:
          return this.store.executionDocumentsOffice;
        case this.states.Ssp.id:
          return this.store.executionDocumentsSsp;
        case this.states.Pfr.id:
          return this.store.executionDocumentsPfr;
        case this.states.Bank.id:
          return this.store.executionDocumentsBank;
      }
      return [];
    },
    async onCardDrop(columnId, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;

      if (removedIndex === null && addedIndex === null) {
        return;
      }
      if (removedIndex !== null) {
        //payload.previous = columnId;
      }
      if (addedIndex !== null) {
        if (payload.current.data.dataType == columnId) {
          return;
        }
        this.tryMove(payload, columnId);
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.getColumnChilds(columnId)[index];
      };
    },
    tryMove(item, columnId) {
      this.currentMoveItem = item;
      this.currentMoveItemTo = columnId;
    },
    async itemChange(item) {
      await this.saveMove(item);
    },
    // popup
    cancelMove() {
      this.currentMoveItemTo = null;
    },
    async saveMove(data) {
      await this.store.update(data);
      this.currentMoveItemTo = null;
    },
    async deleteMove(data) {
      let result = confirm(
        "<span class='fs-5 align-middle'>Вы уверены, что хотите отменить перемещение? Карточка ответчика вернется в предыдущее место.</span>",
        "Отмена перемещения"
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          await this.store.deleteMove(data);
          this.currentMoveItemTo = null;
        }
      });
    },

    async generate(data) {
      let result = confirm(
        "<span class='fs-5 align-middle'>Вы уверены, что хотите заново сформировать и заменить существующее заявление?</span>",
        "Формирование документа"
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          await this.store.update(data);
          const documentId = await this.store.generateExecutionDocument(data.inhabitantId, data.current.id);
          data.current.documentId = documentId;
          this.currentMoveItemTo = null;
        }
      });
    },
    async download(data) {
      const self = this;
      let buildingNumber = "";
      if (self.debtorData.house.buildingNumber != null) buildingNumber = `к.${self.debtorData.house.buildingNumber}_`;
      let fileName = `ССП_${self.debtorData.debtor.accountNumber}_${self.debtorData.house.streetName}_д.${self.debtorData.house.houseNumber}_${buildingNumber}кв.${self.debtorData.debtor.flatNumber}.docx`;

      await this.store.downloadExecutionDocument(data.inhabitantId, data.current.id, fileName);
    },
  },
};
</script>

<style>
.dx-overlay-wrapper {
  font-size: 12pt;
}

.respondents-container {
  background-color: #e8effb;
  border: 1px solid #3333;
  height: 190px;
}
.respondents-container > * {
  height: 190px;
}

.respondent {
  width: 420px;
  /* height: 90px; */
}

.row > .state:first-child {
  border-left: 1px solid #3333;
}

.row > .state:last-child {
  border-right: 1px solid #3333;
}

.col.state {
  min-height: 110px;
  background-color: #e8effb;
  border-top: 1px solid #3333;
  box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 1px 1px rgb(0 0 0 / 24%);
}

.dndrop-container {
  position: relative;
  min-height: 110px;
  min-width: 30px;
}

.dndrop-container.horizontal {
  display: flex !important;
  height: 110px !important;
}

.dndrop-container.horizontal > .dndrop-draggable-wrapper {
  height: 100%;
  display: table-cell;
  vertical-align: top;
}

.dndrop-draggable-wrapper {
  box-sizing: border-box;
}

.draggable-item,
.draggable-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(89, 123, 160, 0.667);
  border-top: 5px solid #42b883;
  border-radius: 4px;
  transition: border-color 0.2s linear;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%), 0 0 1px rgb(0 0 0 / 10%);
}

.card-container {
  padding: 10px;
  margin: 5px;
  background-color: #f3f3f3;
  box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 1px 1px rgb(0 0 0 / 24%);
}

.dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: hidden;
  display: block;
}

.dndrop-draggable-wrapper {
  box-sizing: border-box;
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}
</style>
