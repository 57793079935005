<template>
  <div class="filing">
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>3. Решение</strong>
    </div>
    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Номер дела:</label>
        <DxTextBox :show-clear-button="true" class="w-100" v-model="writ.courtDecisionExecutionDocNo" />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Дата решения:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Дата отмены:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionCancelDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Решение суда:</label>
        <DxSelectBox
          v-model="writ.courtDecisionTypeId"
          display-expr="name"
          value-expr="id"
          :data-source="courtDecisionTypesStore"
          :show-clear-button="true"
          class="w-100"
        ></DxSelectBox>
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Дата получения решения:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionReceivingDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>

      <div class="form-group col-sm-auto visually-hidden">
        <label class="form-check-label" @click="writ.courtDecisionIsReturn = !writ.courtDecisionIsReturn">Возврат:</label>
        <div>
          <DxCheckBox v-model:value="writ.courtDecisionIsReturn" />
          <span class="px-2">{{ returnDate }}</span>
        </div>
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-2 mb-2">
        <label>Сумма по решению:</label>
        <div>
          <label>{{ f(debtWithPenalties) }}</label>
        </div>
      </div>
      <div class="form-group col-md-2 mb-2">
        <label>Долг по решению:</label>
        <DxNumberBox class="w-100" :show-clear-button="true" v-model="writ.courtDecisionSum" ref="tbDeliveredSum" format="#,##0.00" />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Пени по решению:</label>
        <DxNumberBox
          class="w-100 me-3"
          :show-clear-button="true"
          v-model="writ.courtDecisionPenalties"
          ref="tbPenalties"
          format="#,##0.00"
        />
      </div>
      <div class="form-group col-md-4 mb-2">
        <label>Гос. пошлина по решению:</label>
        <div class="d-flex me-2">
          <DxNumberBox
            class="w-100 me-2"
            :show-clear-button="true"
            v-model="writ.courtDecisionStateFee"
            ref="tbStateFee"
            format="#,##0.00"
          />
          <DxButton class="mt-auto w-100" type="default" text="Скопировать суммы из Подготовки" @click="copyAdditionalInfo" />
        </div>
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-4 mb-2">
        <label>Дата получения исп.документа:</label>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionReceivingExecDocDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
    </div>

    <div class="form-row row">
      <div class="form-group col-md-8 mb-2">
        <label>Комментарий:</label>
        <DxTextArea class="w-100" :show-clear-button="true" v-model="writ.courtDecisionComment" height="60" />
      </div>
    </div>

    <div class="row d-none">
      <div class="col-xl-4 d-flex align-items-center">
        <span>Номер дела:</span>
        <DxTextBox :show-clear-button="true" class="w-100" v-model="writ.courtDecisionExecutionDocNo" />
      </div>
    </div>
    <div class="row d-none">
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2 text-nowrap">Дата решения:</span>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2 text-nowrap">Дата отмены:</span>
        <DxDateBox
          class="w-100"
          :show-clear-button="true"
          v-model="writ.courtDecisionReturnDate"
          :use-mask-behavior="true"
          placeholder="введите или выберите"
        />
      </div>
    </div>
    <div class="row d-none">
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2 text-nowrap">Решение суда:</span>
        <DxSelectBox
          v-model="writ.courtDecisionTypeId"
          display-expr="name"
          value-expr="id"
          :data-source="courtDecisionTypesStore"
          :show-clear-button="true"
          class="w-100"
        ></DxSelectBox>
      </div>
    </div>
    <div class="row d-none">
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2 text-nowrap">Сумма к решению:</span>
        <DxNumberBox class="w-100" :show-clear-button="true" v-model="writ.courtDecisionSum" />
      </div>
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2">Гос. пошлина по решению:</span>
        <DxNumberBox class="w-100" :show-clear-button="true" v-model="writ.courtDecisionStateFee" />
      </div>
    </div>
    <div class="row d-none">
      <div class="col-xl-4 d-flex align-items-center">
        <span class="pe-2">Дата получения исп.документа:</span>
        <DxDateBox class="w-100" :show-clear-button="true" v-model="writ.courtDecisionReceivingDate" />
      </div>
    </div>
    <div class="row d-none">
      <div class="col-xl-6 d-flex align-items-center">
        <span class="pe-2 text-nowrap">Комментарий:</span>
        <DxTextArea class="w-100" :show-clear-button="true" v-model="writ.courtDecisionComment" height="60" />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import DxTextArea from "devextreme-vue/text-area";
import { courtDecisionTypesStore } from "@/helpers/common-store/store";
import { formatDate, formatNumber } from "devextreme/localization";
import { useWritCardStore } from "../../state/writ.card";
import { blink } from "@/helpers";

export default {
  components: { DxTextArea },
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);

    return { writ };
  },
  computed: {
    cancelDate() {
      if (this.writ.courtDecisionCancelDate) return formatDate(new Date(this.writ.courtDecisionCancelDate), "shortDate");
      return "";
    },
    returnDate() {
      if (this.writ.courtDecisionReturnDate) return formatDate(new Date(this.writ.courtDecisionReturnDate), "shortDate");
      return "";
    },
    debtWithPenalties() {
      return (this.writ.courtDecisionSum ?? 0) + (this.writ.courtDecisionPenalties ?? 0);
    },
  },
  data() {
    return {
      courtDecisionTypesStore,
    };
  },
  methods: {
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
    copyAdditionalInfo() {
      blink(this, 3, "tbDeliveredSum");
      blink(this, 3, "tbStateFee");
      blink(this, 3, "tbPenalties");
      this.writ.courtDecisionSum = this.writ.preparationSumToCollect;
      this.writ.courtDecisionStateFee = this.writ.preparationStateFee;
      this.writ.courtDecisionPenalties = this.writ.preparationPenalties;
    },
  },
};
</script>

<style scoped>
span {
  min-width: 150px;
}
</style>
